import { Box } from '@chakra-ui/react';
import React from 'react';

const BubblesRight = () => (
  <Box
    bottom="0"
    opacity="0.3"
    pointerEvents="none"
    position="fixed"
    right="0"
    zIndex={1}
  >
    <Box position="relative">
      <Box
        backgroundColor="rag.green"
        borderRadius="50%"
        bottom="50vh"
        h="6rem"
        position="absolute"
        right="300px"
        w="6rem"
        zIndex={2}
      />
      <Box
        backgroundColor="purple.400"
        borderRadius="50%"
        bottom="25vh"
        h="2.3rem"
        position="absolute"
        right="300px"
        w="2.3rem"
      />
      <Box
        backgroundColor="rag.grey"
        borderRadius="50%"
        bottom="25vh"
        h="5rem"
        position="absolute"
        right="120px"
        w="5rem"
      />
      <Box
        backgroundColor="rag.red"
        borderRadius="50%"
        bottom="18vh"
        h="1.2rem"
        position="absolute"
        right="40px"
        w="1.2rem"
      />
    </Box>
  </Box>
);

export default BubblesRight;
