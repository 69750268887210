import { Box, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import { IoChatbubbleOutline } from 'react-icons/io5';

import SupportModal from '../../TopNav/SupportModal';
import { NavContainer, NavLink, NavLinkWithIcon } from '../styled';

import PrintReportModal from '@/components/PrintReportModal';

const routes = [
  {
    to: 'dashboard',
    title: 'Personal Dashboard',
  },
  {
    to: 'wellbeing-priorities',
    title: 'Wellbeing Priorities',
  },
  {
    to: 'wellbeing-report',
    title: 'Wellbeing Report',
  },
  {
    to: 'ocean-persona',
    title: 'OCEAN Persona',
  },
  {
    to: 'workplace-benefits',
    title: 'Your Workplace Benefits',
  },
];

const EmployeeNav = () => {
  const router = useRouter();
  const { assessmentId } = router.query;
  const {
    isOpen: isReportModalOpen,
    onOpen: onReportModalOpen,
    onClose: onReportModalClose,
  } = useDisclosure();
  const {
    isOpen: isSupportModalOpen,
    onOpen: onSupportModalOpen,
    onClose: onSupportModalClose,
  } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const indexSelected = routes.findIndex(
    x => router.pathname.indexOf(x.to) > -1,
  );

  return (
    <NavContainer>
      {indexSelected > -1 && (
        <Box
          backgroundColor="yellow.400"
          borderRadius="10px"
          height="20px"
          left="-70px"
          position="absolute"
          transform={`translateY(${indexSelected * 38}px)`}
          transition="transform 300ms ease-in-out"
          width="20px"
        />
      )}
      {routes.map(route => {
        const selected = router.pathname.indexOf(route.to) > -1;
        return (
          <NavLink
            href={`/employee/reports/${assessmentId}/${route.to}`}
            key={route.to}
            selected={selected}
            variant="yellow"
          >
            {route.title}
          </NavLink>
        );
      })}
      <NavLinkWithIcon
        icon={AiOutlinePrinter}
        onClick={onReportModalOpen}
        selected={false}
        variant="yellow"
      >
        Download report
      </NavLinkWithIcon>
      {isReportModalOpen && (
        <PrintReportModal
          assessmentId={assessmentId as string}
          isOpen={isReportModalOpen}
          onClose={onReportModalClose}
        />
      )}
      {isMobile && (
        <>
          <NavLinkWithIcon
            icon={IoChatbubbleOutline}
            onClick={onSupportModalOpen}
            selected={false}
            variant="yellow"
          >
            Support
          </NavLinkWithIcon>
          <SupportModal
            isOpen={isSupportModalOpen}
            onClose={onSupportModalClose}
            variant="yellow"
          />
        </>
      )}
    </NavContainer>
  );
};

export default EmployeeNav;
