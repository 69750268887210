import { Flex, Box, Radio, RadioGroup } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import FormWrapper from '@/components/Form/FormWrapper';
import BubblesRight from '@/components/Illustrations/BubblesRight';
import { Section } from '@/components/Layout';
import Page from '@/components/Pages/Page';
import { Button, H1 } from '@/elements';

enum SignInOption {
  EMAIL_ADDRESS = 'email-address',
  PHONE_NUMBER = 'phone-number',
}

const SignIn: React.FC = () => {
  const { push } = useRouter();

  const [selectedSignInOption, setSelectedSignInOption] =
    useState<SignInOption>(SignInOption.EMAIL_ADDRESS);

  const handleSubmit = event => {
    event.preventDefault();
    push(`/auth/sign-in/${selectedSignInOption}`);
  };

  return (
    <Page containBubbles={false} variant="dark">
      <Section mb="4" style={{ position: 'relative', zIndex: 2 }}>
        <FormWrapper marginTop="xl" method="post" onSubmit={handleSubmit}>
          <H1 mb="xl">Welcome to Fruitful Insights</H1>
          <Flex direction="column" color="white.400">
            <Box mb={3}>Log in by using your:</Box>
            {/* TODO: This should be a generic element from @/elements */}
            <RadioGroup
              value={selectedSignInOption}
              onChange={value => {
                setSelectedSignInOption(value as SignInOption);
              }}
            >
              <Radio
                colorScheme="yellow"
                value={SignInOption.EMAIL_ADDRESS}
                paddingRight={4}
                data-testid="signIn_emailOption"
              >
                Email address
              </Radio>
              <Radio
                colorScheme="yellow"
                value={SignInOption.PHONE_NUMBER}
                data-testid="signIn_phoneOption"
              >
                Phone number
              </Radio>
            </RadioGroup>
            <Button
              alignSelf="flex-end"
              type="submit"
              marginTop={12}
              data-testid="signIn_submitSelectionButton"
            >
              Continue
            </Button>
          </Flex>
        </FormWrapper>
      </Section>
      <BubblesRight />
    </Page>
  );
};

export default SignIn;
