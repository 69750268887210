import { Box } from '@chakra-ui/react';
import React from 'react';

import { SectionColumn } from '../Layout';
import Footer, { FooterVariant } from '../Layout/Footer';
import { LogoVariants } from '../Logo';
import EmployeeNav from '../SideNav/EmployeeNav';
import EmployerNav from '../SideNav/EmployerNav';
import { UserVariants } from '../TopNav/NavUser/components/ModalContent';

import Aside from '@/components/Layout/Aside';

type PageProps = {
  bg?: string;
  pb?: number | string;
  children: React.ReactNode;
  variant?: 'dark' | 'light';
  containBubbles?: boolean;
  navVariant?: UserVariants;
  logoVariant?: 'dark' | 'light';
  withNavPanel?: boolean;
  userVariant?: UserVariants;
};

const Page = ({
  children,
  variant = 'light',
  navVariant = null,
  logoVariant = 'light',
  withNavPanel,
  containBubbles = true,
  userVariant = UserVariants.EMPLOYEE,
}: PageProps) => {
  const logoColor = {
    [UserVariants.EMPLOYEE]: `${logoVariant}Yellow`,
    [UserVariants.EMPLOYER]: `${logoVariant}Blue`,
  }[userVariant] as LogoVariants;
  const bgColor = variant === 'light' ? 'gray.50' : 'gray.900';
  const footerVariant =
    variant === 'light' ? `${userVariant}Dark` : `${userVariant}Light`;

  return (
    <Box bg={bgColor} minHeight="100%" position="relative">
      <Box display={['none', 'none', 'block']} position="fixed">
        <Aside containBubbles={containBubbles} navVariant={logoColor}>
          {withNavPanel && navVariant === UserVariants.EMPLOYER ? (
            <EmployerNav />
          ) : withNavPanel && navVariant === UserVariants.EMPLOYEE ? (
            <EmployeeNav />
          ) : null}
        </Aside>
      </Box>
      <Box
        paddingLeft={{
          md: '370px',
        }}
        zIndex="1"
      >
        <SectionColumn minHeight="100vh">{children}</SectionColumn>
        <Footer variant={footerVariant as FooterVariant} />
      </Box>
    </Box>
  );
};

export default Page;
